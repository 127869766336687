/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { postApiCall } from '../../app/apiCalls';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript'; // Adjust based on the language of your code
import 'prismjs/themes/prism.css'; // Example style, you can use another
import '../../styles/components/agentResults/ApiAutomationResult.css'; // Import the CSS file

const ApiAutomationResult = ({ resultData, presignedURL, setPresignedURL }) => {
  const [fileContent, setFileContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (resultData) {
      fetchGeneratedFile(resultData.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData]);

  const fetchGeneratedFile = async (automationId) => {
    try {
      const response = await postApiCall('/automate-ai/presigned-url', {
        file_name: `${automationId}/features/generated.feature`,
        method: 'get_object'
      });
      if (response?.['pre-signed_url']) {
        setPresignedURL(response?.['pre-signed_url']);
        fetchFileContent(response['pre-signed_url']);
      } else {
        throw new Error("Failed to get presigned URL");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Error fetching presigned URL. Please try again.");
    }
  };

  const fetchFileContent = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch file content");
      }
      const text = await response.text(); // Fetch the content as text
      setFileContent(text); // Set the content directly
    } catch (error) {
      console.error(error);
      setErrorMessage("Error fetching file content. Please try again.");
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = presignedURL; // Use the presigned URL directly
    link.setAttribute('download', resultData.generated_automation_file_s3_object_key.split('/').pop()); // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!resultData) return null;

  return (
    <div className='api-automation-result'>
      {/* <div className='api-automation-result-header'>
        <h3>{resultData.testrun_name}</h3>
        <p>{resultData.test_description}</p>
        <button className='download-button' onClick={handleDownload}>Download</button>
      </div> */}
      <p><a href="https://github.com/applied-ai-consulting/aiTest-api-automation-karate-framework" target="_blank" rel="noreferrer">Click here</a> to merge generated API Automation Code in Base Karate Framework.</p>

      <div className='code-area'>
        {errorMessage ? (
          <p className='error-message'>{errorMessage}</p>
        ) : (
          <Editor
            value={fileContent}
            onValueChange={code => setFileContent(code)}
            highlight={code => highlight(code, languages.js)} // Adjust the language as needed
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              border: '1px solid #DDDDDD',
              borderRadius: '0.56vw',
              backgroundColor: '#f5f5f5',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ApiAutomationResult;

