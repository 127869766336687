export function formatCurrentDateTime() {
  const now = new Date();

  // Format hours and minutes
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

  // Format month, day, and year
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(now);

  // Combine into the desired format
  return `${hours}:${minutes} ${ampm} on ${formattedDate}`;
}
