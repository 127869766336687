import React, { useEffect } from 'react';
import '../../styles/components/agentResults/VerifyAWebisteResult.css';
import { formatCurrentDateTime } from '../../utils/formatCurrentDateTime';

const VerifyAWebisteResult = ({ resultData }) => {

  const [tableData, setTableData] = React.useState([]);
  const [filter, setFilter] = React.useState("all");

  useEffect(() => {
    let newTableData = resultData?.testrun_status_details || [];
    if (resultData) {
      if(filter === "pass") {
        newTableData = resultData.testrun_status_details.filter((item) => item.testrun_result_status === "pass");
      }
      else if(filter === "fail") {
        newTableData = resultData.testrun_status_details.filter((item) => item.testrun_result_status === "fail");
      }
      else if(filter === "chrome") {
        newTableData = resultData.testrun_status_details.filter((item) => item.browser_name === "chrome");
      }
      else if(filter === "firefox") {
        newTableData = resultData.testrun_status_details.filter((item) => item.browser_name === "firefox");
      }
      else if(filter === "edge") {
        newTableData = resultData.testrun_status_details.filter((item) => item.browser_name === "edge");
      }
    }
    console.log('newTableData ', newTableData);
    setTableData(newTableData);
  }, [resultData, filter]);

  console.log('tableData ', tableData);

  const getBrowserIcon = (browserName) => {
    switch (browserName) {
      case "chrome":
        return <img src={require('../../assets/chrome.png')} alt="chrome" />
      case "firefox":
        return <img src={require('../../assets/firefox.png')} alt="firefox" />
      case "edge":
        return <img src={require('../../assets/edge.png')} alt="edge" />
      default:
        return <img src={require('../../assets/chrome.png')} alt="chrome" />
    }
  }

  return (
    <div className='verify-a-webiste-result'>
      <div className='verify-a-webiste-result-header'>
        <p>The automated URL testing for {resultData?.url} across various browsers and versions was successfully completed at {formatCurrentDateTime()}.</p> 
      </div>
      <div className='verify-a-webiste-result-filters mt-5 d-flex flex-row justify-content-start align-items-center gap-3'>
        <button className={`verify-a-webiste-result-filter ${filter === "all" ? "active" : ""}`} onClick={() => setFilter("all")}>All</button>
        <button className={`verify-a-webiste-result-filter ${filter === "chrome" ? "active" : ""}`} onClick={() => setFilter("chrome")}>Chrome</button>
        <button className={`verify-a-webiste-result-filter ${filter === "firefox" ? "active" : ""}`} onClick={() => setFilter("firefox")}>Firefox</button>
        <button className={`verify-a-webiste-result-filter ${filter === "edge" ? "active" : ""}`} onClick={() => setFilter("edge")}>Edge</button>
        <button className={`verify-a-webiste-result-filter ${filter === "pass" ? "active" : ""}`} onClick={() => setFilter("pass")}>Pass</button>
        <button className={`verify-a-webiste-result-filter ${filter === "fail" ? "active" : ""}`} onClick={() => setFilter("fail")}>Fail</button>
      </div>

      <div className='table-responsive w-100'>
        <table className='table table-striped verify-a-webiste-result-table'>
          <thead className="thead-dark">
            <tr>
              <th>Browser</th>
              <th>Version</th>
              <th>Resolution</th>
              <th>Passed Tests</th>
              <th>Failed Tests</th>
              <th>Time Taken</th>
            </tr>
          </thead>
          <tbody>
            {
              tableData?.length > 0 ? 
                tableData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{getBrowserIcon(item?.browser_name)}</td>
                      <td>{item?.browser_version}</td>
                      <td>{item?.browser_resolution}</td>
                      <td>{item?.pass}</td>
                      <td>{item?.fail}</td>
                      <td>{item?.time_taken}</td>
                    </tr>
                  )
              })
             : <tr><td colSpan={6} style={{textAlign: "center"}}>No data found</td></tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VerifyAWebisteResult