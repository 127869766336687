import React, { useState } from 'react';
import '../../styles/components/agentForms/TestAnApiForm.css';
import { RxCross1 } from 'react-icons/rx';
import { postApiCall, getApiCall } from '../../app/apiCalls';
import { pollStatus } from '../../utils/getStatus';

const TestAnApiForm = ({ selectedAgent, apiTestType, setShowResult, setResultData }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    sourceType: 'postman',
    // Repo details
    repoUrl: '',
    gitUsername: '',
    gitPassword: '',
    branchName: '',
    // Postman details
    collectionType: 'json',
    collectionFile: null,
    collectionFilename: '',
    envVariables: '',
    // Other automation details
    automationFile: null,
    testRunCommand: '',
    reportFileLocation: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingResult, setIsGeneratingResult] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({
    collectionFile: null,
    automationFile: null
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    collectionFile: null,
    automationFile: null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const uploadFileToS3 = async (file, fileType) => {
    try {
      // Step 1: Get presigned URL
      const response = await postApiCall(`/upload-files/${fileType}`);
      console.log(response);

      // Step 2: Create form data for S3 upload
      const formData = new FormData();
      
      // Append only the necessary fields in the correct order
      formData.append('key', response.fields.key);
      formData.append('AWSAccessKeyId', response.fields.AWSAccessKeyId);
      formData.append('policy', response.fields.policy);
      formData.append('signature', response.fields.signature);
      formData.append('x-amz-security-token', response.fields['x-amz-security-token']);
      formData.append('file', file);

      // Step 3: Upload to S3 using fetch
      const uploadResponse = await fetch(response.url, {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      });

      if (!uploadResponse.ok && uploadResponse.status !== 204) {
        throw new Error('Failed to upload file to S3');
      }

      return response.s3_uri;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  const handleFileSelect = (e) => {
    const { name, files } = e.target;
    if (!files || !files[0]) return;

    setSelectedFiles(prev => ({
      ...prev,
      [name]: files[0]
    }));
  };

  const handleUpload = async (fieldName) => {
    const file = selectedFiles[fieldName];
    if (!file) {
      setErrorMessage('Please select a file first');
      return;
    }

    try {
      const fileType = fieldName === 'collectionFile' ? 'json' : 'zip';
      const s3Uri = await uploadFileToS3(file, fileType);
      
      setUploadedFiles(prev => ({
        ...prev,
        [fieldName]: s3Uri
      }));

      setFormData(prev => ({
        ...prev,
        [fieldName]: file
      }));
    } catch (error) {
      setErrorMessage(`Failed to upload ${fieldName}. Please try again.`);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      name: '',
      description: '',
      sourceType: 'repo',
      repoUrl: '',
      gitUsername: '',
      gitPassword: '',
      branchName: '',
      collectionType: 'json',
      collectionFile: null,
      collectionFilename: '',
      envVariables: '',
      automationFile: null,
      testRunCommand: '',
      reportFileLocation: ''
    });
    setErrorMessage("");
    setShowResult(false);
    setResultData([]);
  };

  const validateForm = () => {
    // Check name first
    if (!formData.name.trim()) {
      return "Test name is required";
    }

    if (apiTestType === 'postman') {
      if (formData.sourceType === 'repo') {
        // Repository validations in order of importance
        if (!formData.repoUrl.trim()) {
          return "Repository URL is required";
        }
        if (!isValidUrl(formData.repoUrl)) {
          return "Please enter a valid repository URL";
        }
        if (!formData.gitUsername.trim()) {
          return "Git username is required";
        }
        if (!formData.gitPassword.trim()) {
          return "Git password is required";
        }
        if (!formData.branchName.trim()) {
          return "Branch name is required";
        }
      } else {
        // Postman collection validations
        if (!uploadedFiles.collectionFile) {
          return "Please wait for the collection file to finish uploading";
        }
        if (formData.collectionType === 'misc' && !formData.collectionFilename.trim()) {
          return "Collection filename is required for miscellaneous files";
        }
      }
    } else {
      // Other automation type validations (karate, rest-assured, robot)
      if (!uploadedFiles.automationFile) {
        return "Please wait for the automation file to finish uploading";
      }
      if (!formData.testRunCommand.trim()) {
        return "Test run command is required";
      }
    }

    return ""; // Return empty string if no errors
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const fetchTestResults = async (testrun_id) => {
    try {
      const response = await getApiCall(`/api-test-results/${testrun_id}`);
      if (response?.status_code === 200) {
        // Set the entire response data structure
        setResultData({
          data: {
            testrun_details_result: response.data.testrun_details_result,
            status_code_distribution: response.data.status_code_distribution,
            pass_fail_counts: response.data.pass_fail_counts,
            testrun_id: response.data.testrun_id,
            testrun_status: response.data.testrun_status
          }
        });
        setShowResult(true);
      } else {
        throw new Error("Failed to fetch test results");
      }
    } catch (error) {
      console.error("Error fetching test results:", error);
      setErrorMessage("Failed to fetch test results. Please try again later.");
    } finally {
      setIsGeneratingResult(false);
    }
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    setShowResult(false);
    setErrorMessage("");
    setResultData(undefined);

    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    let testrun_id = "";
    setIsGeneratingResult(true);

    // Create payload based on form type
    const formDataToSend = new FormData();
    formDataToSend.append('test_name', formData.name);
    formDataToSend.append('test_description', formData.description);

    if (apiTestType === 'postman') {
      formDataToSend.append('source_type', formData.sourceType);
      
      if (formData.sourceType === 'repo') {
        formDataToSend.append('repo_url', formData.repoUrl);
        formDataToSend.append('git_username', formData.gitUsername);
        formDataToSend.append('git_password', formData.gitPassword);
        formDataToSend.append('branch_name', formData.branchName);
        formDataToSend.append('env_variables', formData.envVariables || "");
      } else {
        // For file upload (including misc files)
        if (uploadedFiles.collectionFile) {
          formDataToSend.append('s3_uri', uploadedFiles.collectionFile);
          formDataToSend.append('collection_type', formData.collectionType || 'json');
          
          if (formData.collectionType === 'misc') {
            formDataToSend.append('collection_file_name', formData.collectionFilename);
          }
        }
        formDataToSend.append('env_variables', formData.envVariables || "");
      }
    } else {
      // For other automation types (karate, rest-assured, robot)
      if (uploadedFiles.automationFile) {
        formDataToSend.append('s3_uri', uploadedFiles.automationFile);
      }
      formDataToSend.append('test_run_command', formData.testRunCommand);
      formDataToSend.append('report_file_location', formData.reportFileLocation);
    }

    postApiCall(`api-test/${apiTestType}`, formDataToSend)
      .then((res) => {
        if (res?.status_code === 200) {
          testrun_id = res?.data?.load_test_details?.testrun_id;
          return pollStatus(testrun_id, selectedAgent?.id, res?.data);
        } else {
          throw new Error("Failed to initiate API test");
        }
      })
      .then((res) => {
        if (res?.status_code === 200 && res?.data?.testrun_status === "completed") {
          return fetchTestResults(testrun_id);
        } else {
          throw new Error("Test run did not complete successfully");
        }
      })
      .catch((err) => {
        console.error(err);
        setIsGeneratingResult(false);
        setErrorMessage("Something went wrong. Please try again later");
      });
  };

  // Update input fields to show validation state
  const getInputClassName = (fieldName) => {
    if (errorMessage && !formData[fieldName]) {
      return 'form-input error';
    }
    return 'form-input';
  };

  const renderRightSideContent = () => {
    if (apiTestType === 'postman') {
      return (
        <div className="form-section">
          <div className="form-group source-type">
            <label>Source Type<span className="required">*</span></label>
            <select
              name="sourceType"
              value={formData.sourceType}
              onChange={handleInputChange}
              className={getInputClassName('sourceType')}
            >
              <option value="repo">Repository Details</option>
              <option value="postman">Postman Collection</option>
            </select>
          </div>

          {formData.sourceType === 'repo' ? (
            <div className="repo-fields">
              <div className="form-group">
                <label>Repository URL<span className="required">*</span></label>
                <input
                  type="text"
                  name="repoUrl"
                  className={getInputClassName('repoUrl')}
                  value={formData.repoUrl}
                  onChange={handleInputChange}
                  placeholder="Enter repository URL"
                />
              </div>

              <div className="form-group">
                <label>Git Username<span className="required">*</span></label>
                <input
                  type="text"
                  name="gitUsername"
                  className={getInputClassName('gitUsername')}
                  value={formData.gitUsername}
                  onChange={handleInputChange}
                  placeholder="Enter Git username"
                />
              </div>

              <div className="form-group">
                <label>Git Password<span className="required">*</span></label>
                <input
                  type="password"
                  name="gitPassword"
                  className={getInputClassName('gitPassword')}
                  value={formData.gitPassword}
                  onChange={handleInputChange}
                  placeholder="Enter Git password"
                />
              </div>

              <div className="form-group">
                <label>Branch Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="branchName"
                  className={getInputClassName('branchName')}
                  value={formData.branchName}
                  onChange={handleInputChange}
                  placeholder="Enter branch name"
                />
              </div>
            </div>
          ) : (
            <div className="postman-fields">
              <div className="form-group">
                <label>Upload Collection<span className="required">*</span></label>
                <div className="file-upload-container">
                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      name="collectionFile"
                      onChange={handleFileSelect}
                      accept={formData.collectionType === 'json' ? '.json' : '*'}
                      className={getInputClassName('collectionFile')}
                    />
                  </div>
                  <button
                    type="button"
                    className="upload-button"
                    onClick={() => handleUpload('collectionFile')}
                    disabled={!selectedFiles.collectionFile}
                  >
                    Upload
                  </button>
                </div>
              </div>

              {formData.collectionType === 'misc' && (
                <div className="form-group">
                  <label>Collection Filename<span className="required">*</span></label>
                  <input
                    type="text"
                    name="collectionFilename"
                    className={getInputClassName('collectionFilename')}
                    value={formData.collectionFilename}
                    onChange={handleInputChange}
                    placeholder="Enter collection filename"
                  />
                </div>
              )}

              <div className="form-group">
                <label>Postman ENV Variables</label>
                <input
                  type="text"
                  name="envVariables"
                  value={formData.envVariables}
                  onChange={handleInputChange}
                  placeholder="Enter environment variables"
                />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="form-section">
          <div className="form-group">
            <label>Upload Automation File<span className="required">*</span></label>
            <div className="file-upload-container">
              <div className="file-input-wrapper">
                <input
                  type="file"
                  name="automationFile"
                  onChange={handleFileSelect}
                  accept=".java,.py,.robot,.feature,.zip"
                  className={getInputClassName('automationFile')}
                />
              </div>
              <button
                type="button"
                className="upload-button"
                onClick={() => handleUpload('automationFile')}
                disabled={!selectedFiles.automationFile}
              >
                Upload
              </button>
            </div>
          </div>

          <div className="form-group">
            <label>Test Run Command<span className="required">*</span></label>
            <input
              type="text"
              name="testRunCommand"
              className={getInputClassName('testRunCommand')}
              value={formData.testRunCommand}
              onChange={handleInputChange}
              placeholder="Enter test run command"
            />
          </div>

          <div className="form-group">
            <label>Report File Location<span className="required">*</span></label>
            <input
              type="text"
              name="reportFileLocation"
              className={getInputClassName('reportFileLocation')}
              value={formData.reportFileLocation}
              onChange={handleInputChange}
              placeholder="Enter report file location"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <form className="test-api-form">
        <div className="form-container">
          {/* Basic Details - Left Side */}
          <div className="basic-details-section">
            <div className="form-section">
              <div className="form-group">
                <label>Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="name"
                  className={getInputClassName('name')}
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                  rows="5"
                />
              </div>
            </div>
          </div>

          {/* Conditional Form Fields - Right Side */}
          <div className="conditional-fields-section">
            {renderRightSideContent()}
          </div>
        </div>
      </form>

      <div className="form-button-wrapper">
        <button className="reset-button" onClick={handleReset}>
          <RxCross1 /> Reset
        </button>
        <button 
          className="test-button" 
          disabled={isGeneratingResult} 
          onClick={handleGenerate}
        >
          Test
        </button>
      </div>

      {errorMessage && (
        <p className="error-message">{errorMessage}</p>
      )}
      
      {isGeneratingResult && (
        <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-4'>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <progress id="progress-bar" aria-label="Content loading…"></progress>
          </div>
          <p className='wait-text'>I am working on it, thanks for waiting (~3mins)</p>
        </div>
      )}
    </>
  );
};

export default TestAnApiForm;