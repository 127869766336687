import React from 'react'
import '../styles/components/Footer.css';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import awsConfig from '../app/awsConfig';

const Footer = () => {

  const onSignUp = async () => {
    Auth.configure(awsConfig.Auth);
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log('error signing up:', error);
    }
  }
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer text-center text-lg-start">
      {/* Copyright */}
      <div className="footer-content d-flex flex-row justify-content-between w-100">
        <div className="footer-left-content d-flex flex-column align-items-start">
          <p className="company-name"><strong>Digital Employees by</strong> <img className="logo" src={require("../assets/footer-logo.png")} alt="QualityX" />
          </p>
          <p>USA | Canada | India</p>
          <p><span style={{ marginRight: "0.7vw" }}>Phone:</span>+1 (860) 494 9098, +1 (647) 403 3836 </p>
          <p><span style={{ marginRight: "0.7vw" }}>Email:</span>tia@qualityx.io </p>
          <p><span style={{ marginRight: "0.7vw", textAlign: "end" }}>Social:</span><a
            href="https://www.linkedin.com/company/qualityx/" target="_blank" rel="noreferrer"><img src={require("../assets/linkedIn.png")}
              alt="linkedIn" /></a> <a href="https://twitter.com/QualityX" target="_blank" rel="noreferrer"><img
                src={require("../assets/twitter.png")} alt="twitter" /></a></p>
          <p>
            <img 
              className="featured-badge"
              onClick={() => window.open("https://www.f6s.com/companies/insurtech/united-states/texas/no", "_blank")} 
              src={require("../assets/badge2.png")} 
              alt="featured-badge" 
            />
          </p>
          <p>
            <img 
              className="featured-badge" 
              onClick={() => window.open("https://www.f6s.com/companies/ai-governance/united-states/co", "_blank")} 
              src={require("../assets/badge1.png")} 
              alt="featured-badge" 
            />
          </p>
        </div>

        <div className="footer-right-content d-flex flex-row">
          <div className="ai-builders-links d-flex flex-column align-items-start">
            <h4>AI Builders</h4>


            <Link to="/ai-builders" onClick={handleLinkClick}>Create Test Scenarios</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Write UAT Tests</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Generate Test Cases</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Build Test Plans</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Test an API - Postman</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Test an API - RestAssured</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Test an API - Karate</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Test Web App - Playwright</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>Automate Web App - Selenium</Link>
            <Link to="/ai-builders" onClick={handleLinkClick}>And More</Link>
          </div>
          <div className="resources-links d-flex flex-column align-items-start">
            <h4>About Us</h4>
            <Link to="/about" onClick={handleLinkClick}>Team</Link>
            <Link to="/signup" onClick={onSignUp}>Sign up</Link>
            <Link to="/contact-us" onClick={handleLinkClick}>Contact us</Link>
            <Link to="/privacy-policy" onClick={handleLinkClick}>Privacy Policy</Link>
            <Link to="/terms-of-use" onClick={handleLinkClick}>Terms of Use</Link>
          </div>
        </div>
      </div>

      <div className="copyright">
        <p><img src={require("../assets/copyright.png")} alt="QualityX" loading="lazy" style={{ marginRight: "0.6vw", width: "1vw", height: "1vw" }} /> QualityX Inc. All Rights Reserved </p>
        <p>Made in Texas with <span style={{ fontSize: '1vw', margin: "0 0.4vw" }}> ❤️ </span> | Top 50 Agentic AI Company</p>  
      </div>
    </footer>
  )
}

export default Footer