import React from 'react';
import '../styles/components/UrlInputForm.css';
import { postApiCall } from '../app/apiCalls';
import { pollStatus } from '../utils/getStatus';

const UrlInputForm = ({ selectedAgent, setShowResult, setResultData }) => {
  const [url, setUrl] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isGeneratingResult, setIsGeneratingResult] = React.useState(false);

  const handleVerify = (e) => {
    e.preventDefault();
    setShowResult(false);
    setErrorMessage("");
    setResultData(undefined);

    if(url?.length < 0 || selectedAgent?.id?.length < 0) {
      setErrorMessage("Please provide a valid url");
      return;
    }

    const formData = new FormData();
    formData.append('url', url);
    let testrun_id = "";

    setIsGeneratingResult(true);
    postApiCall('verify-a-website', formData)
    .then((res) => {
      console.log(res);
      if (res?.status_code === 200) {
        testrun_id = res?.data?.test_details?.testrun_id;
        return pollStatus(res?.data?.test_details.testrun_id, selectedAgent?.id, res?.data);
      } else {
        console.log(res);
        setIsGeneratingResult(false);
        setErrorMessage("Something went wrong. Please try again later");
      }
    })
    .then((res) => {
      console.log(res);
      if (res?.status_code === 200) {
        setResultData({
          url: url,
          testrun_id: testrun_id || "",
          testrun_status_details: res?.data?.testrun_status_details,
        })
        setShowResult(true);
        setIsGeneratingResult(false);
      } else {
        console.log(res);
        setIsGeneratingResult(false);
        setErrorMessage("Something went wrong. Please try again later");
      }
    })
    .catch((err) => {
      console.log(err);
      setIsGeneratingResult(false);
      setErrorMessage("Something went wrong. Please try again later");
    });
  }

  return (
    <>
      <form className='urlinput-form'>
        <p>Provide the Website url</p>
        <div className='urlinput d-flex flex-row align-items-center gap-3'>
          <input type='text' placeholder='Enter website URL' value={url} onChange={(e) => setUrl(e.target.value)} />
          <button type='button' onClick={handleVerify}>Verify</button>
        </div>
        {errorMessage?.length > 0 ? <p className='error-message' style={{ textAlign: 'start' }}>{errorMessage}</p> : null}
      </form>
      {isGeneratingResult ? <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-3'>
         <div className='d-flex flex-row justify-content-center align-items-center'>
          <progress id="progress-bar" aria-label="Content loading…"></progress>
        </div>
        <p className='wait-text'>I am working on it, thanks for waiting (~3mins)</p>
      </div>: null}
    </>
  )
}

export default UrlInputForm