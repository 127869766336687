// generic api calls implemented using axios
import axios from 'axios';
import { Auth } from 'aws-amplify';

export const axiosInstance = axios.create({
  baseURL: window.location.hostname.includes('localhost') ? 'http://localhost:5000' : process.env.REACT_APP_API_BASE_URL,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      console.error('Error getting token:', error);
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 and we haven't retried yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Get current authenticated user and refresh the session
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        
        // Refresh the session
        await cognitoUser.refreshSession(currentSession.getRefreshToken());
        
        // Get new token
        const newSession = await Auth.currentSession();
        const newToken = newSession.getIdToken().getJwtToken();
        
        // Update the original request with new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        
        // Retry the original request with new token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        // Handle refresh token failure (e.g., redirect to login)
        await Auth.signOut();
        window.location.reload();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const getApiCall = async (path, body, options = {}) => {
  try {
    const response = await axiosInstance.get(path, body, options);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postApiCall = async (path, body, options = {}) => {
  try {
    const response = await axiosInstance.post(path, body, options);

    if(Object.keys(response.data).length === 0) {
      return response;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const putApiCall = async (path, body, options = {}) => {
  try {
    const response = await axiosInstance.put(path, body, options);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteApiCall = async (path, body, options = {}) => {
  try {
    const response = await axiosInstance.delete(path, body, options);
    return response.data;
  } catch (error) {
    throw error;
  }
}



