import React from 'react'
import { MdUploadFile } from 'react-icons/md'
import { postApiCall } from '../app/apiCalls';
import { RxCross1 } from "react-icons/rx";

const RequirementsFileForm = ({ selectedAgent, setShowResult, setResultData }) => {
  const [requirementText, setRequirementText] = React.useState("");
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isGeneratingResult, setIsGeneratingResult] = React.useState(false);


  const isFormValid = () => {
    if (requirementText?.length < 100 && uploadedFile === null) {
      setErrorMessage("Please provide requirement text which is at least 100 characters long or upload a file");
      return false;
    }
    else if (requirementText?.length >= 100) {
      setErrorMessage("");
      return true;
    }

    let isValid = false;
    const acceptedFileTypes = ["pdf", "doc", "docx", "txt", "xls", "xlsx"];
    acceptedFileTypes.forEach((type) => {
      console.log(uploadedFile?.name?.includes(type));
      if (uploadedFile?.name?.includes(type)) isValid = true;
    })
    if (!isValid) {
      setErrorMessage("Please upload a file with one of the following extensions: pdf, doc, docx, txt, xls, xlsx");
    }

    return isValid;
  };

  const handleFileRead = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const textContent = event.target.result;
      setRequirementText(textContent); // Update requirement text with file content
    };

    reader.onerror = () => {
      console.error("Error reading file. Attempting with alternate encoding.");
      setErrorMessage("Error reading file. Please check the file format.");
    };

    // Attempt to read as UTF-8
    reader.readAsText(file, "utf-8");
  };

  const handleReset = (e) => {
    e.preventDefault();
    setRequirementText("");
    setUploadedFile(null);
    setErrorMessage("");
    setShowResult(false);
    setResultData([]);
  }

  const handleGenerate = (e) => {
    e.preventDefault();
    if (!isFormValid()) return;
    setIsGeneratingResult(true);
    setErrorMessage("");
    console.log(uploadedFile)

    const formData = new FormData();
    if (requirementText?.length > 0) {
      const encodedText = new Blob([requirementText], { type: "text/plain" });
      const requirementFile = new File([encodedText], "requirement_file.txt", { type: "text/plain" });
      formData.append("requirements_file", requirementFile);
    }
    formData.append('use_case', selectedAgent?.useCase);

    if (uploadedFile) formData.append("requirements_file", uploadedFile);

    postApiCall('generate-artifacts', formData, {
      headers: {
        'Content-Type': 'text/plain',
        'Accept': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        setErrorMessage("");
        setIsGeneratingResult(false);

        try {
          const startIndex = response.test_cases.indexOf('[');
          const endIndex = response.test_cases.lastIndexOf(']');
          const jsonData = response.test_cases.substring(startIndex, endIndex + 1);
          const jsonResponse = JSON.parse(jsonData); // Parse the JSON data

          // Show the generated test cases
          setShowResult(true);
          let resultData = {};
          if (selectedAgent?.link !== 'generate-test-cases') {
            resultData.headers = Object.keys(jsonResponse[0]).map(header => header.replace("_", " "));
            resultData.rows = jsonResponse.map(row => {
              let newRow = [];
              const objectValue = Object.values(row);

              objectValue.forEach((value, index) => {
                if (Array.isArray(value)) {
                  // make the array into a string of bullet points
                  newRow[index] = value.map(item => `- ${item}`).join("\n");
                  newRow[index] = newRow[index].replace("[", "").replace("]", "");
                }
                else if (typeof value === 'object') {
                  newRow[index] = JSON.stringify(value, null, 2).replace(/\{|\}|"/g, "");
                }
                else {
                  newRow[index] = value;
                }
                if (newRow[index]?.length === 0) newRow = '-'
              })

              return newRow;
            });

            setResultData(resultData);
          }
        } catch (error) {
          setIsGeneratingResult(false);
          console.log(error);
        }
      })
      .catch((error) => {
        setIsGeneratingResult(false);
        console.log(error);
        setErrorMessage("Error while generating test cases. Please try again.");
      });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);

    // Only attempt to read as text if it's a text-based file
    if (file.type === "text/plain") {
      handleFileRead(file);
    }
  };

  return (
    <>
      <form className="agent-form">
        <div className='agent-textarea-wrapper'>
          <p className='agent-textarea-label'>{selectedAgent.link === 'generate-test-cases' ? 'Provide the test scenario in detail below' : ' Provide the requirement in detail below'}</p>
          <textarea value={requirementText} onChange={(e) => setRequirementText(e.target.value)} class="agent-textarea" id="exampleFormControlTextarea1" rows="9"></textarea>
        </div>

        <p className='agent-from-or'>OR</p>

        <div className='agent-req-upload-wrapper'>
          <p className='agent-req-upload-label'>Upload the requirement document</p>
          <div className='agent-req-upload d-flex flex-column align-items-center justify-content-center'>
            <MdUploadFile style={{ height: "2vw", width: "1.8vw" }} />
            <p>{uploadedFile ? "File uploaded successfully" : "Drag & Drop files or"}</p>
            <label for="file-upload" class="custom-file-upload">
              {uploadedFile ? uploadedFile.name : "Choose a file"}
            </label>
            <input type="file" accept="application/*, text/*, application/pdf, application/document, application/msword, application/xls" onChange={(e) => handleFileUpload(e)} className="file-input" id="file-upload" />
          </div>
        </div>
      </form>
      {errorMessage?.length > 0 ? <p className='error-message'>{errorMessage}</p> : null}
      {isGeneratingResult ? <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-3'>
         <div className='d-flex flex-row justify-content-center align-items-center'>
          <progress id="progress-bar" aria-label="Content loading…"></progress>
        </div>
        <p className='wait-text'>I am working on it, thanks for waiting (~1min)</p>
      </div>: null}
      <div className="agent-button-wrapper mt-5 w-100 d-flex flex-row justify-content-center align-items-center gap-3">
        <button onClick={(e) => handleReset(e)}><RxCross1 /> Reset</button>
        <button disabled={isGeneratingResult} type="button" onClick={(e) => handleGenerate(e)} class="generate-button">Generate</button>
      </div>
    </>
  )
}

export default RequirementsFileForm