import React from 'react';
import ReactECharts from 'echarts-for-react';
import '../../styles/components/agentResults/TestAnApiResult.css';
import { formatCurrentDateTime } from '../../utils/formatCurrentDateTime';

const TestAnApiResult = ({ resultData }) => {
  if (!resultData) return null;

  const { 
    testrun_details_result: details,
    status_code_distribution,
    pass_fail_counts
  } = resultData.data;

  // Ensure statusData has at least one element and handle the new structure
  const statusCounts = status_code_distribution?.[0]?.status_counts || {};
  const hasStatusData = Object.keys(statusCounts).length > 0;

  const hasPassFailData = pass_fail_counts && (pass_fail_counts?.total_passed || pass_fail_counts?.total_failed);

  // Bar Chart Options
  const statusBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: Object.keys(statusCounts),
      axisTick: {
        alignWithLabel: true
      },
      name: 'Status Codes',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: {
      type: 'value',
      name: 'Count',
      nameLocation: 'middle',
      nameGap: 40
    },
    series: [{
      name: 'Status Codes',
      type: 'bar',
      barWidth: '60%',
      data: Object.values(statusCounts).map(value => ({
        value,
        itemStyle: {
          color: '#20264D'
        }
      })),
      emphasis: {
        itemStyle: {
          color: '#2f3b73'
        }
      }
    }]
  };

  // Pie Chart Options
  const passFailPieOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      bottom: 'bottom'
    },
    series: [{
      name: 'Test Results',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { 
          value: pass_fail_counts?.total_passed || 0, 
          name: 'Passed',
          itemStyle: { color: '#28a745' }
        },
        { 
          value: pass_fail_counts?.total_failed || 0, 
          name: 'Failed',
          itemStyle: { color: '#f78181' }
        }
      ]
    }]
  };

  return (
    <div className='test-api-result'>
      <div className='test-api-result-header'>
        <p>The API testing was successfully completed at {formatCurrentDateTime()}.</p>
      </div>

      <div className='test-api-stats-grid'>
        <div className='stat-card'>
          <h3>{details.total_api}</h3>
          <p>Total APIs</p>
        </div>
        
        <div className='stat-card success'>
          <h3>{details.pass_api_count}</h3>
          <p>Passed</p>
        </div>
        
        <div className='stat-card error'>
          <h3>{details.fail_api_count}</h3>
          <p>Failed</p>
        </div>
        
        <div className='stat-card'>
          <h3>{(details.total_time / 1000).toFixed(1)}s</h3>
          <p>Total Time</p>
        </div>
        
        <div className='stat-card'>
          <h3>{details.users}</h3>
          <p>Users</p>
        </div>
      </div>

      <div className='test-api-charts'>
        <div className='chart-container'>
          <h4>API Status Code Distribution</h4>
          {hasStatusData ? (
            <ReactECharts option={statusBarOptions} style={{ height: '400px' }} />
          ) : (
            <div className='feature-under-development'>Feature Under Development</div>
          )}
        </div>
        
        <div className='chart-container'>
          <h4>Pass/Fail Distribution</h4>
          {hasPassFailData ? (
            <ReactECharts option={passFailPieOptions} style={{ height: '400px' }} />
          ) : (
            <div className='feature-under-development'>Feature Under Development</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestAnApiResult;